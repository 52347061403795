@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

body {
  background-color: #f6f8ff !important;
  font-family: "Inter", sans-serif !important;
  color: #1a1a1a !important;
}
::selection {
  background-color: rgb(0, 0, 113, 63%);
  color: #fff;
}
.no-margin-bottom-last > *:last-child {
  margin-bottom: 0 !important;
}
.no-margin-right-last > *:last-child {
  margin-right: 0 !important;
}
.form-control {
  color: #000 !important;
}

.form-control:focus {
  border-color: #000071 !important;
  box-shadow: 0 0 0 0.25rem rgb(0, 0, 113, 63%) !important;
}

.signin-component,
.forget-component {
  background-color: white;
}

.register-patient {
  color: #f26522 !important;
  font-size: 18px !important;
  font-weight: bold;
}

.generic-heading-level-2 {
  font-weight: 600;
}

.dt-round-img {
  height: 46px;
  width: 46px;
  border-radius: 50%;
}

.loader-custom-h {
  height: 100vh;
}

.user-details-card .loader-custom-h,
.custom-loading-h .loader-custom-h,
.patient-pharmacy .loader-custom-h {
  height: auto;
}

.text-cursor-pointer {
  cursor: pointer;
}

.btn-header {
  background: linear-gradient(0deg, #39b6fe, #39b6fe), #ffffff;
  border-radius: 4px;
  border: none;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.tele-consultation-tabs .nav-tabs .nav-link.active {
  border-top: 5px solid #000071;
  color: #000000;
  border-right: none;
  border-left: none;
  border-radius: 0px;
}

.tele-consultation-tabs .nav-tabs .nav-link:hover {
  background-color: transparent;
  border-color: transparent;
}

.tele-consultation-accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.tele-consultation-tabs .nav-link {
  color: #b3b3b3;
}

.tele-consultation-accordion .accordion-collapse {
  background-color: #fbfbfb;
  color: #1a1a1a;
  font-weight: 400;
  font-size: 16px;
}

.tele-consultation-accordion .accordion-header button {
  background-color: #fbfbfb;
  color: #1a1a1a;
  font-weight: 400;
  font-size: 16px;
}

.tele-consultation-accordion .accordion-button:focus {
  box-shadow: none;
}

.tele-consultation-accordion .accordion-item {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border: 1px solid #cccccc !important;
}

.appointment-notes-accordion .accordion-header .accordion-button {
  padding: 25px 48px;
  font-size: 22px;
  font-weight: 500;
  color: #1a1a1a;
  background-color: #ffffff;
}

.appointment-notes-accordion .accordion-item {
  border: 0px;
}

.appointment-notes-accordion .form-check-label {
  font-size: 18px;
  font-weight: 500;
  padding: 0px 10px;
  margin: 3px 0px;
}

.appointment-notes-accordion .form-check-input {
  width: 20px;
  height: 20px;
}

.appointment-notes-accordion .form-check-input:checked,
.edit-prescription-modal .form-check-input:checked {
  background-color: #000071;
  border-color: #000071;
}

.custom-field-picker {
  border: 1px solid #d9d9d9 !important;
  border-radius: 5px !important;
  padding: 11px !important;
}

.dr-date-w {
  width: 160px;
}

.patient-profile-dob-w {
  width: 160px;
}

.patient-profile-cd-w {
  width: 164px;
}

.doctors-apt-modal-table thead {
  display: none;
}

.doctors-apt-modal-table
  .react-bootstrap-table
  table
  tbody
  tr:nth-of-type(even) {
  background: none !important;
}

.doctors-apt-modal-table .react-bootstrap-table table tbody tr {
  cursor: pointer;
}

.patient_apt-scheduling .custom__date-input {
  margin-bottom: 0 !important;
}
.doctors-apt-modal-table .react-bootstrap-table {
  max-height: 13rem;
  overflow: scroll;
}

.custom-field-picker .react-time-picker__wrapper {
  border: none;
  color: #999999;
}

.custom__date_icon {
  position: absolute;
  right: 0;
  color: #999999;
  transform: translate(-10px, 16px);
}

.custom-date-icon {
  position: relative;
  right: 24px;
  top: 15px;
  color: #999999;
}

.med-certificate .custom-date-icon {
  position: absolute;
  right: 15px;
  top: 17px;
  color: #999999;
}
.med-certificate .form-control:disabled,
.med-certificate .form-select:disabled {
  background: transparent;
}

.prescription-date-picker {
  position: relative;
  right: 30px;
  top: 16px;
  color: #999999;
}

.custom-padding-date {
  padding: 12px 20px;
}

.patient-date-wrapper .custom-date-icon {
  position: relative;
  right: 23px;
  top: 16px !important;
  color: #999999;
}

.appointment-notes-accordion .react-time-picker__clock {
  display: none;
}

.header-icon {
  width: 50%;
}

.users__nav-list .nav-item {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0;
}
.users__nav-list .nav-item a {
  background: #fff;
  color: #b3b3b3;
  padding: 14px 30px;
}

.current-pharmacy-detials {
  background: #fbfbfb !important;
  border: 1px solid #cccccc !important;
  border-radius: 7px !important;
  box-sizing: border-box !important;
}

.user-menu .badge {
  background-color: #f26522 !important;
}

.notification-icon {
  position: absolute;
  right: 16.1%;
  top: 4.9px;
  font-size: 6px !important;
}

.inner-page-wrapper {
  margin-top: 78px;
  padding: 36px 36px 0px 70px !important;
}

.label-primary {
  font-weight: 500 !important;
  font-size: 18px !important;
}

input::placeholder,
.card-content > span p:nth-child(1) {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #999999 !important;
  padding-right: 10px !important;
}

.card-content > span p:nth-child(2) {
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #1a1a1a !important;
}

.card-content > span p,
.card-content > hr {
  margin: 0px;
}

.card-content span {
  padding: 28px 0px;
}

.otpfield {
  padding: 12px 6px !important;
}

.otp-field {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: 100%;
  font-weight: 500;
  font-size: 31px;
}

.text-gray {
  color: #999999;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
}

.text-black {
  font-weight: 400;
  font-size: 20px;
}

.head-user-text {
  font-weight: 500;
  font-size: 20px;
  color: #1a1a1a;
}

.head-role-text {
  font-weight: 400;
  font-size: 14px;
  color: #1a1a1a;
}

.modal-footer {
  border-top: none !important;
}

.header-user-text {
  font-weight: 500;
  font-size: 16px;
  color: #1a1a1a;
}

.bell-icon {
  color: #b3b3b3;
  cursor: pointer;
}

.search-field-spacing {
  padding: 11px 16px !important;
}

.searchbar-icon {
  position: absolute;
  right: 10px;
  top: 14px;
  color: #b3b3b3;
}

.table-bold-text {
  font-weight: 500;
  font-size: 16px;
}

.table-normal-text {
  font-weight: 400;
  font-size: 16px;
}

.table-action {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #3f8bfc !important;
  text-decoration: none;
}

.card {
  border: none !important;
}

.custom-scrollbar {
  height: 100%;
  max-height: calc(100vh - 382px);
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-prescription-scroll {
  height: 100%;
  max-height: calc(100vh - 242px);
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-table-spacing table {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.notify-dot::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #f26522;
  border: 1px solid #ffffff;
}

.action-data {
  color: #ffffff;
  background-color: #000071;
  border-radius: 5px;
  text-align: center;
  min-width: 30%;
  cursor: pointer;
  padding: 0px 20px;
}

.doctors-apt-table table tr td:nth-last-child(1) {
  display: flex;
  justify-content: center;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.patient__booked-apt .doctors-apt-table table tr td:nth-last-child(1) {
  justify-content: start !important;
}
.superadmin-custom-scrollbar {
  height: 100%;
  max-height: calc(100vh - 732px);
  overflow-y: auto;
  overflow-x: hidden;
}

.patient__nav-card {
  cursor: pointer;
  background-color: #fff !important;
  border: 1px solid #f26522 !important;
  color: #f26522 !important;
}
.patient__nav-card:hover {
  background-color: #f26522 !important;
  color: #fff !important;
}

.admin__count-card:hover,
.admin__count-card:hover h3 {
  cursor: pointer;
  background-color: #f26522;
  color: #fff;
}
.admin__count-card:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(791%)
    hue-rotate(294deg) brightness(116%) contrast(104%);
}

/* Full Calendar */
.fc-timegrid-event-harness {
  width: 100%;
}
.fc-timegrid-event-harness .fc-v-event {
  background-color: transparent;
}
.fc .fc-button {
  border: 1px solid #e9e9e9 !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04) !important;
  color: #cccccc;
  border-radius: 5px !important;
  padding-left: 20px !important;
  font-weight: 600 !important;
  padding-right: 20px !important;
  text-transform: capitalize;
}
.fc-daygrid-dot-event:hover {
  background-color: transparent;
}
.chat-box-shadow-lt {
  border-radius: 12px 0px 0px 12px;
  box-sizing: border-box;
  box-shadow: 0 0 8px #c3c3c3;
  -webkit-clip-path: inset(-5px 0px -5px -5px);
  clip-path: inset(-5px 0px -5px -5px);
}

.chat-box-shadow-rt {
  border-radius: 0px 12px 12px 0px;
  box-sizing: border-box;
  box-shadow: 0 0 8px #c3c3c3;
  -webkit-clip-path: inset(-5px -5px -5px 0px);
  clip-path: inset(-5px -5px -5px 0px);
}

.fc .fc-button:hover {
  color: #cccccc;
}

.fc-button-active {
  color: #1a1a1a !important;
  font-weight: 500 !important;
}

.fc-toolbar-title {
  font-size: 22px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  padding: 0px 10px !important;
}

.fc-header-toolbar.fc-toolbar > div:nth-child(2) > div {
  display: flex !important;
  align-items: center !important;
}

.apt-badge .bg-primary {
  background-color: #f2f2f2 !important;
  color: #1a1a1a !important;
}

.apt-badge .bg-danger {
  background-color: #ffefd7 !important;
  color: #f26522 !important;
}

.apt-badge .bg-info {
  background-color: #e2f3ff !important;
  color: #2269f2 !important;
}

.apt-badge .bg-light {
  background-color: #ecffcc !important;
  color: #44bc19 !important;
}

.apt-badge .bg-warning {
  background-color: #ffe5e5 !important;
  color: #fd2121 !important;
}

.fc-header-dropdown .dropdown button {
  position: absolute !important;
  right: 1px !important;
  background: white !important;
  color: #cccccc !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
}

.fc-header-dropdown .dropdown-menu,
.apt-filter-dropdown .dropdown-menu {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0px;
  padding: 10px;
}

.fc-header-dropdown .form-check-input:checked,
.apt-filter-dropdown .form-check-input:checked {
  background-color: #000071;
  border-color: #000071;
}

.generic-h100 .card {
  height: 100%;
}

.fc-media-screen .fc-header-toolbar > div:nth-child(2) {
  padding-right: 12%;
}

.fc-scroller table thead tr th {
  background-color: #f1f4ff;
  padding: 8px 0px;
}

.fc-scroller table thead tr th .fc-scrollgrid-sync-inner a {
  font-size: 15.5521px;
  color: #232a3e;
  font-weight: 600;
  text-decoration: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
  color: #232a3e;
  font-weight: 500;
  font-size: 19.4401px;
}

.fc-h-event {
  border-radius: 6px !important;
  margin: 0px 14px !important;
  padding: 14px 12px !important;
}

.fc-daygrid-day-events {
  overflow: scroll !important;
  max-height: 120px;
  /* margin-bottom: 15px; */
}

.fc-view-harness {
  z-index: 0;
}

.fc-button-group {
  z-index: 0;
  flex-wrap: wrap;
}

.fc-daygrid-day-frame {
  background-color: #ffffff !important;
  /* position: relative; */
  min-height: 75%;
  height: 150px;
  /* text-align: center !important; */
  /* overflow: auto !important; */
}

.main-row .card h3 {
  display: flex !important;
}

/* .main-row img {
  width: 44px;
} */

.highcharts-credits {
  display: none !important;
}

.bnLuHU {
  background-color: white !important;
}

.custom-date {
  padding: 9px 8px !important;
  border: 1px solid #cccccc !important;
  color: #999999 !important;
}

.table > :not(caption) > * > * {
  border-bottom: 0px;
}

.react-bootstrap-table table tbody tr:nth-of-type(odd) {
  background-color: white;
}

/* .react-bootstrap-table table tbody tr:nth-of-type(even) {
  background-color: #8d8dc4 !important;
} */

.react-bootstrap-table table {
  margin-bottom: 0px;
}

.react-bootstrap-table table thead tr th {
  padding: 15px 24px;
  font-size: 15px;
}

.center-text {
  text-align: center;
}

.react-bootstrap-table table tbody tr td {
  padding: 10px 22px !important;
  vertical-align: middle;
  font-size: 15px;
}

.dr-list-spacing .react-bootstrap-table table tbody tr td {
  padding: 23px 22px !important;
}

.pagination > li > a {
  background-color: #f5f5f5;
}

.pagination > .active > a,
.patient__prescription-pagination .page-item.active .page-link {
  background-color: #000071 !important;
  border-color: #000071 !important;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.custom-outline-btn {
  padding: 11px 20px !important;
  background-color: #ffffff !important;
  color: #000071 !important;
  border: 1px solid #000071 !important;
}

.custom-outline-filter {
  padding: 6px 8px !important;
  background-color: #ffffff !important;
  border: 1px solid #cccccc !important;
}

.apt-filter-dropdown .dropdown-toggle::after {
  display: none;
}

.prescription-outline-btn {
  background-color: #ffffff !important;
  color: #000071 !important;
  border: 1px solid #000071 !important;
}

.custom-dropdown button {
  background: #ffffff !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  color: #cccccc !important;
  padding: 11px 18px !important;
}

.calendar-card {
  background: #fafbff;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}

select {
  font-weight: 400 !important;
  font-size: 12.4687px !important;
}

.breadcrum_Project a {
  text-decoration: none !important;
  color: #1a1a1a !important;
  font-size: 24px !important;
  text-transform: capitalize;
}

.simple-header .breadcrum_Project {
  font-size: 37px;
}

.breadcrumb-item::before {
  display: none;
}

.breadcrum_addProject {
  color: #818181 !important;
}

.breadcrumb-item.active {
  color: #1a1a1a !important;
}

.header-breadcrumb ol {
  margin-bottom: 0px;
  font-weight: bold !important;
  font-size: 22px !important;
}

.table-custom-data {
  font-size: 18px;
}

.btn-closeAndBack {
  border: 0 !important;
  background-color: transparent !important;
  font-weight: 600;
  font-size: 15.4332px;
  color: #000000;
}

.custom-modal .btn-primary {
  background: #000071 !important;
  border: none !important;
}

/* .btn-primary {
  background: #000071;
  border-radius: 5px;
  padding: 12px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  border: none;
} */

/* .signin-component .btn-primary, */
.forget-component .btn-primary {
  background: #000071 !important;
  border: none !important;
}

.btn-draft {
  border: var(--bs-border-width) var(--bs-border-style) #0971ad !important;
  background-color: transparent !important;
  font-weight: 600;
  font-size: 14px;
  color: #0971ad;
  padding: 4px 8px !important;
  border-radius: 4px !important;
}

.auth__bg-img {
  background-image: linear-gradient(rgb(0, 0, 113, 63%), rgb(0, 0, 113, 63%)),
    url("../images/doctor-bg4.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  background-position-x: right;
  position: relative;
}

.signin-component .signin-page,
.forget-component .signin-page {
  position: relative;
  background: rgb(0, 0, 113, 63%);
  height: 100vh;
}

.auth-text-section {
  max-width: 60%;
  position: absolute;
  top: 42%;
  left: 38%;
  transform: translate(-50%, -40%);
}
.auth-text-section h1 {
  margin-bottom: 2.5rem;
}

.custom-outline-btn {
  background-color: transparent !important;
  color: #000071 !important;
  font-size: 18px !important;
  border: 1px solid #000071 !important;
  padding: 10px 34px !important;
}

.signin-component .heading1,
.forget-component .heading1 {
  display: block;
  font-weight: 700;
  font-size: 4rem;
  color: #ffffff;
}

.signin-component .heading2,
.forget-component .heading2 {
  font-weight: 500;
  font-size: 1.5rem;
  color: #ffffff;
}
.signin-col-2 {
  height: 100vh;
  overflow: scroll;
}

.signin-component .signin-content,
.forget-component .signin-content {
  width: 30rem;
  margin: auto;
}

.signin-heading {
  font-weight: 700;
  font-size: 38px;
  margin-bottom: 10px;
}

.eye-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #999999;
  cursor: pointer;
}

.back-sign-in,
.back-sign-in:hover {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  color: #1a1a1a;
}

.eJVmkY {
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.error-message-field-generic {
  color: #f22222;
  font-size: 14px;
}

.forget-pass small {
  color: #74acff !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-decoration: underline !important;
}

.appointment-modal-title {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #1a1a1a;
}

.profile-img-test {
  max-width: 40%;
}

.video-appointment {
  background-color: #000000;
  height: 40rem;
}

.video-appointment .not-joined-text {
  font-weight: 400;
  color: #ffffff;
  font-size: 20px;
  max-width: 36%;
  line-height: 26px;
}

.exclamation-icon {
  color: #ffb400;
}

.video-appointment .botton-icons,
.appointment-started .botton-icons {
  cursor: pointer;
  padding: 15px 5px;
  color: white;
  background: #000071;
  border-radius: 50%;
}

.appointment-modal.custom-modal-size .modal-dialog {
  max-width: 640px;
  width: 100%;
}

.appointment-modal .modal-content {
  border-radius: 20px;
}

.appointment-modal .modal-header {
  padding-top: 26px;
  border-bottom: 0px;
}

.stethoscope-icon {
  color: #000071;
  padding: 16px;
  background: #edeafd;
  border-radius: 50%;
}

.start-apt-btn {
  background: #000071 !important;
  padding: 10px 34px !important;
  border: none !important;
}

.end-apt-btn {
  background: #fd2121 !important;
  padding: 10px 34px !important;
  border: none !important;
}

.referral-main-text {
  color: #999999;
  font-weight: 500;
  font-size: 20px;
}

.color-99 {
  color: #999999 !important;
}

/*--------- Edit Dropdown---------- */
.moreIcon {
  color: black;
  border: none;
  background: none;
}

.moreIcon .dropdown-toggle::after {
  visibility: hidden;
}

.set-dropdown-menu {
  overflow-x: hidden !important;
  font-size: 14px !important;
}

.edit-dropdown .dropdown-menu {
  --bs-dropdown-min-width: 7rem;
}

.edit-dropdown .dropdown-menu {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: none;
}

/* ---------------------- */

.simple-header {
  background-color: #ffffff;
  height: 165px;
}

.policies-header {
  background-color: #ffffff;
  height: 100px;
}

.prescription-btn {
  background: #00007114 !important;
  color: #000071 !important;
  border-radius: 5px !important;
  border: none !important;
}

.prescription-modal-title {
  font-weight: 500;
  font-size: 24px;
}

.prescription-modal .form-check-label {
  color: #999999;
  font-weight: 400;
  font-size: 16px;
}

.edit-btn {
  background-color: #f1f9ff !important;
  color: #2269f2 !important;
  border-radius: 7px !important;
  font-weight: 500 !important;
  font-size: 19px !important;
}

.custom-text-underline {
  color: #000071;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  float: right;
}

.patient-calendar
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.patient-calendar .react-datepicker__day:hover {
  background-color: #f26522 !important;
  border-radius: 50% !important;
  color: #fff !important;
}

.patient-calendar .react-datepicker__month {
  margin: 0px;
}

.patient-calendar .react-datepicker__header,
.patient-calendar .react-datepicker__month-container {
  background: #f9f9f9 !important;
  border: 0px;
  width: 100%;
}

.patient-calendar .react-datepicker__month-container {
  overflow: auto;
}

.patient-calendar .react-datepicker__day-name {
  color: #666666;
}

.patient-calendar .react-datepicker__day-name,
.patient-calendar .react-datepicker__day {
  width: 2.6rem;
  font-size: .8rem;
  line-height: 2.5rem;
  margin: 0px 5px;
}

.patient-calendar .react-datepicker {
  width: 100%;
  border: 0px !important;
}

.patient-calendar .react-datepicker__current-month {
  font-size: 1rem;
  margin: 0px;
}

.patient-calendar .react-datepicker__navigation-icon::before {
  border-color: #333333;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 10px;
}

.patient-calendar .react-datepicker__navigation--previous {
  left: 34px;
  top: 5px;
}

.patient-calendar .react-datepicker__navigation--next {
  right: 34px;
  top: 5px;
}

.dr-apt-overview .highcharts-legend-item text {
  font-size: 15px !important;
}

.apt-inner-card {
  border: 0.8px solid #000071;
  border-radius: 5.6px;
}

.upcoming__apt-section {
  max-height: 11rem;
  overflow-y: scroll;
}

.print-data {
  color: #0971ad !important;
}

.print-data .Toastify__progress-bar-theme--light {
  background: #0971ad !important;
}

.print-data .Toastify__toast-icon svg {
  fill: #0971ad !important;
}

.notification-img {
  width: 35px;
  background-color: #f4f6f9;
  height: 35px;
  border-radius: 50px;
  display: flex !important;
  margin-right: 0.5rem !important;
}

.notify-name {
  width: 100% !important;
  text-align: center !important;
  align-self: center !important;
  font-weight: 700;
}

.notification-custom-icons {
  width: 48px;
  height: 44px;
  border-radius: 50%;
}

.accept-btn {
  background: #71d76f !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 2px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.reject-btn {
  background: #39b6fe !important;
  border-radius: 4px !important;
  border: none !important;
  padding: 2px 8px !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.sidebar-log-img {
  max-width: 100%;
  height: auto;
  height: 62px;
  width: 170px;
}

body .modal-backdrop.show {
  --bs-backdrop-bg: unset;
}

body .fade.modal-backdrop.show {
  --bs-backdrop-bg: #000;
}

.fc .fc-daygrid-event-harness {
  margin-bottom: 10px;
}

.appointment-calender a.fc-event {
  padding: 0 !important;
}

.appointment-slot-background {
  width: 100%;
  min-width: 120px;
  padding: 15px;
  border-radius: 5px;
  text-wrap: wrap;
}

.subscriber .OTSubscriberContainer {
  width: 100% !important;
  height: 700px !important;
}

.videocall.publisher {
  position: absolute;
  width: 100%;
  top: 68%;
  bottom: 0;
}

.videocall.publisher .publisher-video-box {
  min-height: 12rem !important;
}

.OT_publisher .OT_mute,
.OT_subscriber .OT_mute {
  display: none !important;
}

.OT_publisher .OT_bar,
.OT_subscriber .OT_bar {
  display: none !important;
}

.consultation-main .nav-tabs .nav-item .active {
  border-top-left-radius: 5px;
}

.payment-checkout .checkout-country-col .form-control {
  padding: 0.65rem 0.75rem !important;
}

.familyDoc-zipcode.form-select {
  border-radius: 0.375rem 0.375rem 0px 0px !important;
}

.publisher .publisher-name,
.subscriber .publisher-name {
  background: rgba(0, 0, 0, 0.3);
  padding: 5px 10px;
  border-radius: 25px;
}

.subscriber .publisher__name-section,
.publisher .publisher__name-section {
  position: absolute;
  width: fit-content;
  inset: 36rem 0 0 1rem;
}

.publisher .publisher-mute {
  background: #fd2121;
  border-radius: 50%;
}

/*  Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

.patient-pharmacy .react-bootstrap-table table tbody tr td {
  padding: 18px 42px !important;
}

.activeChat {
  background: #eee;
  border-radius: 5px;
  transition: all ease-in-out 0.3s;
}

.hoverChat:hover {
  background: #eee !important;
  border-radius: 5px !important;
  transition: all ease-in-out 0.3s !important;
}

.chat__upload-doc {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  width: 25%;
  height: 180px;
  margin-bottom: 5px;
  cursor: pointer;
}

.chat__upload_text {
  white-space: nowrap;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9c9c9c;
  font-size: 16px;
}

.patient-note .patient-notes-data {
  max-height: 20.5rem;
  overflow: scroll;
}

.referrel-note .referrel-note-data {
  max-height: 20rem;
  overflow: scroll;
}

.user-menu .notification-item .dropdown-menu {
  width: 27.8rem;
  padding: 0;
  min-height: calc(100vh - 42rem) !important;
}

.pdf-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.pdf-table td,
.pdf-table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.pdf-table tr:nth-child(even) {
  background-color: #dddddd;
}

.custom-text-ellipsis {
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-link,
.text-link:hover {
  text-decoration: none;
  color: #1a1a1a;
}

* {
  box-sizing: border-box;
}

body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
}

.stripe label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.stripe input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #c4c4c4ff;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripe input::placeholder {
  color: #aab7c4;
}

.stripe input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.stripe .StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.stripe .StripeElement.PaymentRequestButton {
  height: 40px;
}

.modal-stripe .modal-content {
  width: 400px;
}

.custom-full-calendar .fc-popover-body {
  height: 200px;
  overflow: scroll;
}

/* .custom-full-calendar .fc-header-dropdown .dropdown .dropdown-menu {
  position: absolute;
  left: 75.4rem;
  top: 2.2rem;
} */

.uploaded-img-layout {
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: contain;
}

.rotate-img {
  animation: rotate 3s linear infinite;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.pt-ref-notes .accordion-button {
  font-size: 1.25rem !important;
}

.pt-ref-notes .specialist-letter .accordion-button {
  color: #2269f2 !important;
}

.pt-ref-notes .emergency-letter .accordion-button {
  color: #e31e27 !important;
}

.pt-ref-notes .medical-certificate .accordion-button {
  color: #44bc19 !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  border: 1px solid #d9d9d9 !important;
  height: 48px !important;
}
.react-tel-input .flag-dropdown {
  padding: 23px 0px !important;
}

.verify-btn .btn-primary {
  padding: 7px !important;
  border: none !important;
  right: 0;
  top: 0;
  height: 2.8rem;
}
.form-check-input:checked {
  background-color: #000071 !important;
  border: none;
}

/* Account Settings - Start */
.account__settings-layout {
  max-width: 42rem;
  height: auto;
}
.account__settings-layout .card-title {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 32px;
}
.account__settings-layout h5 {
  font-size: 22px;
  line-height: 22px;
  text-align: center;
}
.account__settings-layout .password-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 24.5px;
  text-align: center;
  color: #999999;
}

/* Account Settings - End */

.questionnaires-option [placeholder^="Add option"]:focus,
.questionnaires-option [placeholder^="Add option"]:hover {
  border-bottom: 1px solid #cacaca !important;
  border-radius: 0px;
  box-shadow: none;
}
.questionnaires-option .form-check-label {
  width: 100%;
}
.patient__prescription-forms {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 1.5rem;
}
.patient__prescription-cards {
  background-color: #eeebff;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  height: 6rem;
  width: auto;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  text-align: center;
}

.accepted-form {
  color: #44bc19;
}

.rejected-form {
  color: #ff3a3a;
}

.patient__prescription-cards:hover {
  background-color: #000071;
  color: #fff;
}
.doctor-prescription-dashbaord {
  display: grid;
  gap: 1rem;
}
.doctor-prescription-tabs {
  display: grid;
  gap: 0.6rem;
}

.ds-top {
  position: absolute;
  margin: auto;
  right: 0;
  top: 0;
  left: 0;
  height: 13.5rem;
  background-color: #000071;
  -webkit-clip-path: ellipse(68% 40% at 49% 27%);
  clip-path: ellipse(68% 40% at 49% 27%);
}
.avatar-holder {
  position: absolute;
  margin: auto;
  top: 40px;
  right: 0;
  left: 0;
  width: 9.2rem;
  height: 9.2rem;
  border-radius: 50%;
  background: white;
  border: 3px solid #fff;
  overflow: hidden;
}
.avatar-holder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.name {
  position: absolute;
  margin: auto;
  top: 14rem;
  right: 0;
  bottom: 0;
  left: 0;
  width: inherit;
  height: 40px;
  text-align: center;
  font-size: 1.5rem;
}

.patient-personal-details {
  margin-top: 17rem;
}

.doctor-medication-modal .modal-dialog {
  width: 100%;
}
.policies-text {
  margin: 20px 0px 0px 0px;
  color: #74acff;
  font-weight: 500;
}
.policies {
  text-align: justify;
}

.img-obj-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.color-dk-blue {
  filter: brightness(0) saturate(100%) invert(6%) sepia(79%) saturate(7492%)
    hue-rotate(231deg) brightness(84%) contrast(138%);
}
.nav-btn-active {
  background-color: #f26522 !important;
  border: none !important;
}
.nav-btn {
  color: #b3b3b3 !important;
  background-color: #fff !important;
  border: none !important;
}

.card-content > hr:last-child {
  display: none;
}
.input-field-below-text {
  color: #9e9e9e;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 10px;
}

.custom-plans .container,
.my-plans .container {
  width: 24rem;
  padding: 20px;
  background: #ffffff;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
  position: relative;
}
.patient__subscription-plan {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
  gap: 0.5rem;
}

.my-plans .container {
  width: 100% !important;
}
.custom-plans .container .price {
  color: #f26522;
  font-weight: 700;
  font-size: 2.2rem;
  margin: 15px 0;
}
.custom-plans .container span {
  font-size: 1.2rem;
}
.custom-plans .container .description {
  margin: 20px 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: justify;
}
.custom-plans .container .offer {
  display: block;
  color: #555;
  font-size: 1rem;
  margin-top: 25px;
}
.custom-plans .subscribe-button {
  display: inline-block;
  padding: 5px 0px !important;
  background: none;
  border: 2px solid #f26522;
  color: #f26522;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1.2rem;
  margin-top: 40px;
  width: 40%;
  font-weight: 500;
  transition: 0.2s ease;
}
.custom-plans .subscribe-button:hover,
.custom-plans .subscribe-button:active {
  background: #f26522 !important;
  border: 2px solid #f26522 !important;
  color: #fff !important;
}
.custom-plans .ribbon-wrap,
.my-plans .ribbon-wrap {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  pointer-events: none;
}
.custom-plans .ribbon,
.my-plans .ribbon {
  width: 150px;
  font-size: 0.918rem;
  text-align: center;
  padding: 8px 0;
  position: absolute;
  right: 0px;
  top: 6%;
}
.ribbon-wrap .active-status {
  color: #44bc19;
  background-color: #ecffcc;
  border-radius: 0;
  font-size: 15px;
}
.ribbon-wrap .inactive-status {
  color: #1a1a1a;
  background-color: #f2f2f2;
  border-radius: 0;
  font-size: 15px;
}
.day-left-status {
  color: #ff0000;
  background-color: rgb(255, 0, 0, 10%);
  border-radius: 70px;
}
.plans-count {
  background-color: #eeebff;
  border-radius: 5px;
  padding: 10px 34px;
}
.active > .page-link {
  z-index: 0 !important;
}
.pagination__page-Number li {
  padding: 0px 4px;
}

.notification__badge .badge {
  position: absolute !important;
  top: -1.5rem !important;
  left: -0.95rem !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
  border-radius: 50% !important;
  border: 1px solid #ffffff !important;
}
.notification__badge-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
}

.main-personalprofile .patient__info-date > div {
  margin-bottom: 0 !important;
}

.slots__creation-modal .tab-content {
  max-height: 28rem;
  overflow-y: scroll;
  overflow-x: hidden;
}
.personal__profile-layout {
  min-height: 44rem;
}
.personal__profile-layout .container-fluid {
  height: 44rem;
}
.personal__profile-layout .tabs_panel,
.personal__profile-layout .tab-content {
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 24px 0px #0000000a;
  border-radius: 10px;
}
.personal__profile-layout .nav-item a {
  color: #999999 !important;
  background-color: transparent !important;
  padding: 0 !important;
}
.personal__profile-layout .nav-item a span {
  font-size: 19px !important;
  font-weight: 500 !important;
  padding: 25px 0;
}
.personal__profile-layout .tabs_panel .nav-item:not(:last-child) a span {
  border-bottom: 1px solid #999999 !important;
}
.personal__profile-layout .tabs_panel .nav-item .nav-link.active {
  color: #000071 !important;
}

.personal__profile-layout .tab__content-header {
  height: 10rem;
  background: #000071;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.personal__profile-layout .tab__content-header img {
  /* top: 70px;
  left: 25px; */
  height: 155px;
  width: 155px;
  object-fit: cover;
  border-radius: 50%;

  /* border: 5px solid #FFFFFE !important; */
  /* border-width: thick; */
  /* background-color: white; */
}
.personal__profile-layout .tab__content-header .profile__edit-btn {
  color: #000071;
  font-size: 16px;
  font-weight: 600;
}
.personal__profile-layout .tab__content-header .profile__upload-btns button {
  font-size: 15px;
  width: 140px;
  height: 50px;
}
.personal__profile-layout .tab__content-header .user__outer-section {
  border-radius: 50% !important;
  height: 165px;
  width: 165px;
  position: relative;
  transform: translate(30px, 80px);
  border: 5px solid #fff !important;
}
.profile__change-btn {
  padding: 12px !important;
  color: #fff;
  background-color: #000071 !important;
}
.profile__remove-btn {
  padding: 12px !important;
  background-color: #f4f4f4 !important;
  color: #999999 !important;
}
.personal__profile-layout .tab__content-body {
  margin: 5.5rem 2rem;
}

.personal__profile-layout .tab__content-body .main-title {
  position: relative;
  padding-left: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  height: 28px;
  display: flex;
  align-items: center;
}

.personal__profile-layout .tab__content-body .main-title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background-color: #000071;
  border-radius: 4px;
}
.profile__field-label {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #9c9c9c;
}

.profile__field-value {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #1a1a1a;
}
.profile__edit-section input.form-control {
  height: 50px;
}

.patient__prescription-forms.custom-plans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
  gap: 1.5rem;
}
.personal__profile-layout .accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent !important;
}
.patient__medical-details {
  height: 48rem;
  overflow-y: scroll;
}

/* Chat Section - Start */

.user__chat-list .custom__search-input {
  margin-right: 0 !important;
}
.user__chat-list .user__chat-item .user-name {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.user__chat-list .user__chat-item .user-message {
  display: inline-block;
  font-size: 18px;
  color: #c3c3c3;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user__chat-list .user__chat-item .user-date {
  font-size: 12px;
  font-weight: 500;
  color: #9c9c9c;
}
.user__chat-list .user__chat-item:hover,
.user__chat-list .selected {
  cursor: pointer;
  color: #fff;
  background-color: #4c4c9b;
  border-radius: 6px;
}
.user__chat-item:hover .user-message,
.user__chat-item:hover .user-name,
.user__chat-item:hover .user-date,
.user__chat-item {
  color: #fff;
}

.user__chat-list .user__chat-item:hover img,
.user__chat-list .selected img {
  border: 2px solid #fff;
}
.user__chat-item .message-count {
  font-size: 14px;
  width: fit-content;
  background: #000071;
  float: inline-end;
  border-radius: 5px;
  padding: 0px 6px;
  color: #fff;
}

.user__chat-room .chat__room-header {
  height: 4rem;
  border-bottom: 0.5px solid #c3c3c3;
}
.user__chat-room .chat__room-main,
.staff__chat-room .user__chat-room .chat__room-main {
  height: calc(100vh - 340px);
  background-image: url("../images/chat-room-bg.png");
  object-fit: contain;
  overflow-y: scroll;
}
.chat__room-dayText {
  box-shadow: 0px 0px 19px 0px #0000001a;
  color: #999999;
  font-size: 1rem;
  border-radius: 2.5rem;
}
.message__section-wrapper {
  max-width: 56%;
}
.message__section-wrapper .time-text {
  color: #9c9c9c;
  font-size: 12px;
  font-weight: 400;
}
.chat__room-msg-text {
  font-size: 1rem;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 400;
  white-space: normal;
}
.chat__room-receiverMessage {
  border: 1px solid #e3e3e3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  /* width: fit-content; */
}
.chat__room-senderMessage {
  color: #fff;
  border: none;
  background-color: #000071;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.user__chat-room .chat__room-footer {
  width: 100%;
  box-sizing: border-box;
  border-top: 0.5px solid #c3c3c3;
}

.chat__room-footer .emoji-icon:hover,
.chat__room-footer .upload__clip-icon:hover {
  cursor: pointer;
  color: #000071 !important;
}
.chat__room-footer .file-name {
  font-size: 14px;
  text-decoration: underline;
  font-weight: 500;
}
.chat__room-footer .message__input-field {
  resize: none;
  height: 55px;
  padding: 12px 16px;
}
.chat__room-footer .send__msg-icon {
  position: absolute;
  top: 4px;
  right: 10px;
  background-color: #000071 !important;
  padding: 10px !important;
  border: none !important;
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;
}
.user__chat-room textarea::-webkit-scrollbar {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  width: 0;
  display: none;
}
/* Chat Section - End */

.patient__nav-card-wrapper {
  margin-bottom: 20px;
}

.patient-dashboard-grid {
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}
.patient-dashboard-grid .card-title {
  width: fit-content;
  font-size: clamp(1rem, 1.25vw, 1rem);
}
.patient-dashbaord .card-title {
  font-size: clamp(1rem, 1.25vw, 1rem);
}
.md-dashboard-prescription-card {
  margin-bottom: 20px;
}

@media screen and (min-width: 576px) and (max-width: 1080px) {
  .patient-dashboard-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 1rem;
  }

  .patient-dashboard-grid > *:nth-last-child(1):nth-child(odd) {
    grid-column: span 2;
  }
}

@media screen and (min-width: 1080px) {
  .patient-dashboard-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (max-width: 364px) {
  .patient__prescription-cards {
    width: 88% !important;
  }
}

@media screen and (max-width: 394px) {
  .personal__profile-layout .tab__content-header .rm__position-alignment {
    display: block !important;
    padding-top: 0.5rem;
  }
  .rm__position-alignment svg {
    margin: 0 !important;
  }
  .rm__position-alignment span {
    display: none;
  }
  .patient__subscription-plan {
    grid-template-columns: unset;
  }
  .my-plans.patient__prescription-forms .container h4 {
    font-weight: unset !important;
    margin-top: 3rem !important;
    font-size: 18px;
  }
  .patient__prescription-forms {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 1.5rem;
  }
}

@media screen and (max-width: 513px) {
  .patient__prescription-forms.custom-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    gap: 1.5rem;
  }
  .personal__profile-layout .tab__content-header .profile__upload-btns {
    display: flex;
    top: 5.5rem !important;
    left: 8rem !important;
  }
  .personal__profile-layout .tab__content-header .profile__upload-btns button {
    width: 50%;
    padding: 10px 0 !important;
  }
  .profile__change-btn .profile__remove-btn {
    padding: 0px;
  }
  .personal__profile-layout .tab__content-header .user__outer-section {
    height: 100px;
    width: 100px;
    top: 2.5rem !important;
  }
  .personal__profile-layout .tab__content-header img {
    height: 90px;
    width: 90px;
  }
}

@media screen and (min-width: 576px) and (max-width: 796px) {
  .display-column-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .md-text-center {
    justify-content: center;
  }
}

@media screen and (max-width: 348px) {
  .forget-pass {
    width: 100%;
    text-align: right;
  }
  .fc-media-screen .fc-header-toolbar > div:nth-child(2) div button {
    width: 20%;
    justify-content: center;
    display: flex;
  }
  .fc-toolbar-title {
    padding: 0 !important;
  }
}

@media screen and (max-width: 427px) {
  .create__slots-section .slots__fees-btn {
    width: 100%;
    display: flex;
    place-content: end;
  }
}

@media (max-width: 490px) {
  .users__nav-list .nav-item,
  .Add-Admin {
    width: 100%;
  }
  .users__nav-list .nav-item {
    margin-right: 0;
  }
}

@media screen and (min-width: 490px) and (max-width: 627px) {
  .users__nav-list .nav-item {
    width: 48%;
  }
}
@media screen and (min-width: 463px) and (max-width: 627px) {
  .md-dashboard-prescription-card {
    margin-bottom: 55px;
  }
}
@media screen and (max-width: 462px) {
  .md-dashboard-prescription-card {
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 576px) {
  .signin-component .signin-content {
    margin: 0;
  }
  .xs-margin-bottom {
    margin-bottom: 20px;
  }

  .breadcrumb_xs-none {
    display: none;
  }
  .custom-col-rev {
    flex-direction: column-reverse;
  }
  .text-w-full {
    width: 100%;
  }
  .subscriber .publisher__name-section {
    position: absolute;
    inset: 1rem 0 0 1rem;
  }
  .staff__chat-room > div {
    height: auto !important;
  }
  .patient-dashboard-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
  .patient__nav-card-wrapper {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 600px) {
  .doctor-prescription-dashbaord {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 623px) {
  .doctor-prescription-tabs {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 627px) {
  .user-details-card .custom__search-input,
  .user-details-card .custom__select-input,
  .user-details-card .custom__date-input,
  .user-details-card .custom__date-input .dr-date-w,
  .superadmin-date-wrapper .custom__search-input,
  .superadmin-date-wrapper .custom__date-input,
  .superadmin-date-wrapper .custom__date-input .dr-date-w,
  .patient-profile-wrapper .custom__search-input,
  .patient-profile-wrapper .create__fee-btns,
  .patient-profile-wrapper .create__fee-btns button,
  .patient-dashboard-table .custom__search-input,
  .patient-dashboard-table .custom__select-input,
  .patient-dashboard-table .custom__date-input,
  .patient-dashboard-table .custom__date-input .dr-date-w,
  .patient-dashboard-table .custom-outline-btn,
  .dr__my-patient .custom__search-input,
  .dr__my-patient .custom__date-input,
  .dr__my-patient .custom__date-input .dr-date-w,
  .dr__my-patient .custom__select-input,
  .payment-dashboard-table .custom__search-input,
  .payment-dashboard-table .custom-outline-btn,
  .doctor-patient-paymentdetails .custom__search-input,
  .doctor-patient-paymentdetails .custom__date-input,
  .doctor-patient-paymentdetails .dr-date-w,
  .prescription-forms .custom__search-input {
    width: 100%;
    margin-right: 0 !important;
  }
  .selects-section,
  .doctor-patient-paymentdetails .usermanagement-mainclass button {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .signin-component .heading1,
  .forget-component .heading1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 991px) {
  .inner-page-wrapper {
    margin-top: 78px;
    padding: 20px !important;
  }
}

@media screen and (max-width: 1086px) {
  .personal__profile-layout .tab__content-header .profile__upload-btns button {
    font-size: 15px;
    width: 100px;
    height: 40px;
  }
}

@media screen and (max-width: 1453px) and (min-width: 1200px) {
  .patient-dashboard-table .selects-section,
  .payment-dashboard-table .selects-section {
    width: 100% !important;
  }
}

@media screen and (max-width: 1293px) and (min-width: 1200px) {
  .patient-dashboard-table {
    height: 478px !important;
  }
}

@media screen and (max-width: 1232px) and (min-width: 1200px) {
  .payment-dashboard-table {
    height: 439px !important;
  }
}

@media screen and (max-width: 1200px) {
  .patient__medical-details {
    height: auto;
    overflow-y: unset;
  }
  .message__section-wrapper {
    max-width: 80%;
  }
}

@media screen and (max-width: 1330px) {
  .fc-header-dropdown .dropdown {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
  }
  .fc-header-dropdown .dropdown button {
    position: unset !important;
  }
  .fc-media-screen .fc-header-toolbar > div:nth-child(2) {
    padding-right: 0;
  }
}
.infinite__scrolling-dropdown .dropdown-list {
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  margin-top: 10px;
  padding: 5px;
  width: 200px;
  max-height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1000;
}
.infinite__scrolling-dropdown .dropdown-list .dropdown-item {
  padding: 8px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.infinite__scrolling-dropdown .dropdown-list .dropdown-item:hover {
  background-color: #5959a3;
  color: #fff;
}

@media screen and (min-width: 1080px) and (max-width: 1353px) {
  .patient-dashboard-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .patient__nav-card-wrapper {
    margin-bottom: 0px !important;
  }
  .md-margin-dashboard-card {
    margin-top: 20px !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .sm-margin-dashboard-card {
    margin-bottom: 20px !important;
  }
}
