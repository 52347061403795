/* Generic buttons style */

.primary_bg {
  background-color: #000071 !important;
  border: none !important;
}

.primary_color {
  color: #000071 !important;
}

.primary_outline {
  background-color: transparent !important;
  border: 1px solid #000071 !important;
  color: #000071 !important;
}

.primary_outline:hover {
  background-color: #000071 !important;
  border: 1px solid #000071 !important;
  color: #fff !important;
}

.secondary_bg {
  background-color: #cccccc !important;
  border-color: #cccccc !important;
  color: #000 !important;
}

/* Chat Module */

@media screen and (max-width: 600px) {

  .mobileFlex_Col {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .profileContent {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

/* Slots Module */

.slotsSection .form-check-input:checked[type="radio"] {
  background-image: none !important;
  padding: 3px !important;
}

.slotsSection .slick-list {
  margin: 0 35px !important;
}

.slotsSection .slick-arrow {
  width: 30px !important;
}

.slotsSection .slick-prev {
  left: 0 !important;
}

.slotsSection .slick-next {
  right: 0 !important;
}

.slotsSection .slick-slider svg {
  color: #000 !important;
}

.slotsSection .dateItem {
  transition: all 0.2s ease-in-out;
  width: fit-content !important;
  border-radius: 8px !important;
  padding: 7px 8px;
  width: 55px !important;
  cursor: pointer;
}

.slotsSection .dateItem:focus,
.slotsSection .dateItem .activeDate {
  background-color: #eeebff;
}

.slotsSection .dateItem:focus p {
  color: #000071 !important;
}

.slotsSection .dateItem:focus h5 {
  color: #000071 !important;
}

.slotsSection .dateItem-section {
  margin-top: 20px;
}

.slotsSection .slot_btn {
  background-color: #81d363; /*#eeebff*/
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 10px 4px !important;
}

.slotsSection .slot_btn:hover, .slotsSection .slot_btn:active {
  background-color: #44BC19 !important;
}
.disable__btn-hover .slot_btn:hover, .disable__btn-hover .slot_btn:active, .disable__btn-hover .slot_btn:focus {
  background-color: #81d363 !important;
}
.slotsSection .booked,
.slotsSection .booked:hover, .slotsSection .booked:focus {
  background-color: #ff6060 !important; /* #f8f8f8 */
  color: #fff !important;
}

.slotsSection .blocked, .slotsSection .blocked:hover, .slotsSection .blocked:focus{
  background-color: #B3B3B3 !important;
  color: #fff !important;
}

.slotsSection .slotContainer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 25px;
}

.slotsSection .monthSelect {
  border-radius: 3px !important;
  font-size: 18px !important;
  color: #666666 !important;
  padding: 15px 12px !important;
}

.slotsSection button {
  border: 0 !important;
}
.slotsSection .custom-date{
  padding: 14px 10px !important;
  color: #212529 !important;
}
#bookedRadio .form-check-input:checked {
  background-color: #cccccc !important;
  border-color: #cccccc !important;
}

.radioGroup .blockedSlot {
  border: 1px solid #B3B3B3;
  padding: 3px 5px 5px 5px;
}

.radioGroup .bookedSlot {
  border: 1px solid #FD2121;
  padding: 3px 5px 5px 5px;
}

.radioGroup .availableSlot {
  border: 1px solid #81d363;
  padding: 3px 5px 5px 5px;
}

.radioGroup span {
  font-weight: 600;
  font-size: 17px;
  color: #3e3e3e;
}

#availableRadio .form-check-input:checked {
  background-color: #a391ff;
}

#availableRadio .form-check-input {
  border-color: #a391ff;
}

#bookedRadio .form-check-input,
#availableRadio .form-check-input {
  width: 2em;
  height: 2em;
  margin-right: 8px;
  margin-top: 0 !important;
}

#bookedRadio .form-check-input:checked:focus,
#availableRadio .form-check-input:checked {
  box-shadow: none !important;
}

/* Modal - Add new slot */

.addSlot_content .nav-tabs {
  border-bottom: 1px solid #999999 !important;
}

.addSlot_content .nav-tabs .nav-item .active,
.addSlot_content .nav-tabs .nav-item :focus {
  background-color: transparent !important;
  border-bottom: 2px solid #000071 !important;
  font-weight: 600 !important;
  color: #000071 !important;
  font-size: 16px !important;
  border-top: 0px !important;
}

.addSlot_content .nav-tabs .nav-item :hover {
  background-color: transparent !important;
}

.addSlot_content .nav-tabs .nav-link {
  color: #b3b3b3 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.addSlot_content .nav-tabs .nav-link:first-child {
  margin-right: 15px !important;
}

.slot_tab .formSelect {
  height: 45px !important;
}

/* Generic for all Modal Close Buttons */
.btn-close {
  opacity: 1 !important;
}

/* Generic for all Modals */
.modal {
  display: flex !important;
  align-items: center !important;
}

.modalCheckboxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.modalCheckboxes .form-check-input:focus {
  box-shadow: none !important;
}

.modalCheckboxes .form-check-input:checked {
  background-color: #000071 !important;
}

.modalCheckboxes label {
  color: #8b8b8b;
  font-weight: 400;
  font-size: 16px;
  align-self: end !important;
  margin-left: 10px;
}

.slot_btn {
  position: relative;
}

.slot_btn:hover .delete-icon {
  visibility: visible;
}

.slot_btn .delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  background-color: white;
  visibility: hidden;
}

@media screen and (max-width: 1500px) {
  .slotsSection .slotContainer {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
  }

  .slotsSection .slot_btn {
    font-size: 12px !important;
    padding: 10px 4px !important;
  }
}

@media screen and (max-width: 1300px) {
  .slotsSection .slotContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (max-width: 992px) {
  .modalCheckboxes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .slotsSection .slotContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
}
@media screen and (max-width: 1199px) {
  .slotsSection .radioGroup {
    width: 100% !important;
  }
}
@media screen and (max-width: 600px) {
  .slotsSection .slotContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  .slotsSection .slot_btn {
    font-size: 12px !important;
    padding: 10px 2px !important;
  }

  .slotsSection .radioGroup {
    display: flex !important;
    justify-content: center !important;
  }

  .modalCheckboxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .slotsSection .dr-date-w, .slotsSection .custom__date-input, .slotsSection .d__date-text{
    width: 100%;
  }
}

@media screen and (max-width: 376px) {
  .modalCheckboxes {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Patient - Doctor List */

.patient-doctorList .searchBar {
  padding: 12px 8px !important;
}

.patient-doctorList .searchbar-icon {
  position: absolute !important;
  top: 15px !important;
  right: 10px !important;
}

.patient-doctorList .locationBar {
  padding: 13px 8px;
}

.patient-doctorList .locationBar-icon {
  position: absolute;
  top: 15px !important;
  right: 10px !important;
  color: #999999;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 1500px) {
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .searchBar {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .searchBar {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Generic for All Select Menus */

.form-select {
    font-size: 16px !important;
    color: #999999 !important;
    /* padding: 11px 8px !important; */
    padding: 13px 56px 13px 10px !important;
}

/* Patient - Doctor Details */

.doctor_Details .slot_btn:focus {
  background-color: #44BC19; /*000071*/
  color: #fff !important;
}

.upload_pic input[type="file"], .profile__upload-btns  input[type="file"] {
  opacity: 0;
  height: 0;
  width: 0.1px;
  position: absolute;
  z-index: -1;
}

.upload_pic .upload-text {
  color: #000071 !important;
}

.upload_pic .upload-text_small {
  color: #999999;
  font-size: 14px;
}

.text_darkGray {
  color: #868686;
}

@media screen and (max-width: 1200px) {
  .doctor_info {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 600px) {
  .doctor_info_content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .text_center_mobile {
    text-align: center;
  }
}

/* Complete Profile */

.profileHeader {
  position: fixed;
  top: 0;
  right: 0;
  left: 313px;
  height: 80px;
  background-color: #fff;
  box-shadow: 36px 0px 25px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #f0f0f0;
  z-index: 1000;
}

.profileHeader .progress {
  height: 0.5rem !important;
}

.profileHeader .progress-bar {
  background-color: #44bc19 !important;
}

/*  */
.profileSidebar {
  position: fixed;
  top: 0;
  left: -300px;
  bottom: 0;
  z-index: 1032;
  height: 100%;
  width: 300px;
  box-shadow: 3px 0px 25px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  background: #fff;
  transition: transform 0.3s ease-in-out;
}

.profileSidebar.visible {
  transform: translateX(300px);
}

@media (min-width: 992px) {
  .sidebar-toggle {
    display: none;
  }

  .profileSidebar {
    left: 0;
  }
}

@media (max-width: 991px) {
  .sidebar-toggle {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1033;
    width: 30px;
    height: 30px;
    background: #fff;
    border: none;
    outline: none;
  }

  .profileSidebar .hamburger {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
  }
}

/*  */
.profileSidebar li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  color: #999999;
  transition: all 0.3s ease;
}

.profileSidebar li a.active,
.profileSidebar li a:focus {
  color: #000071;
}

.profileSidebar .spanBorder {
  border: 2.2px solid #e2e2e2;
  transition: all 0.3s ease;
}

.profileSidebar li a.active .spanBorder,
.profileSidebar li a:focus .spanBorder {
  border: 2.2px solid #000071 !important;
}

.profile_wrapper {
  margin-left: 320px;
  margin-top: 95px;
}

@media screen and (max-width: 991px) {
  .profile_wrapper {
    margin-left: 0px;
  }

  .profileHeader {
    position: fixed;
    left: 0;
  }
}

.profile_upload button {
  background-color: transparent !important;
  color: #000071 !important;
  outline-color: #000071 !important;
  border-radius: 5px !important;
  border: 1.8px solid #000071 !important;
  padding: 11px 46px !important;
  font-weight: 500 !important;
}

@media screen and (min-width: 1200px) {
  .width_75 {
    width: 75%;
  }
}

/* Medical History */

.medical_history .accordion {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
}

.medical_history .accordion-button:not(.collapsed) {
  background-color: #fff !important;
  border-bottom: 1px solid #cccccc !important;
}

.medical_history .accordion-button {
  color: #1a1a1a !important;
  font-weight: 600 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 20px;
}

.medical_history .accordion {
  --bs-accordion-btn-focus-box-shadow: none !important;
}

.accordion-button::after {
  color: #999999 !important;
  background-image: url(/src/assets/images/accordion-arrow.png) !important;
  background-color: #f6f6f6 !important;
  padding: 20px !important;
  background-position: center !important;
  border-radius: 5px !important;
}

.accordion_content:hover .action_btns {
  visibility: visible;
}

.textarea-style {
  padding-right: 60px;
}

.accordion_content .action_btns {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  width: 10%;
  background-color: white;
  /* visibility: hidden; */
}

.accordion_content .action_btns img {
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .accordion_content .action_btns {
    position: absolute;
    top: 0;
    right: 50%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 10%;
    background-color: white;
    visibility: hidden;
  }

  .accordion_content:hover {
    padding-top: 50px !important;
    padding-right: 10px !important;
  }

  .medical_history .accordion_content {
    margin: 15px 0px !important;
  }
}

.profileSuccess_modal .modal-header {
  border-color: transparent !important;
}
